<template>
  <el-dialog title="添加应用"
             :visible.sync="showDialog"
             width="500px"
             :closeOnClickModal="false"
             @open="initData"
             :before-close="closeFn">
    <el-form :model="form"
             ref="ruleForm"
             status-icon
             :rules="rules"
             label-width="100px">
      <el-form-item label="应用名称："
                    prop="name">
        <div class="flex-start">
          <el-input v-model.trim="form.name"
                    placeholder="请输入用户名"
                    clearable
                    maxlength='64'
                    show-word-limit
                    class='inputWidth'/>
          <!-- <span class="font12 "
                style="line-height:14px;width:180px">大小写字母，数字和下划线的组合，不超过64个字符</span> -->
        </div>
      </el-form-item>
      <el-form-item label="code编码：" prop="code">
         <el-input v-model.trim="form.code"
                    placeholder="请输入编码"
                    clearable
                    class='inputWidth' /></el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="sureFn"
                 >确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>

    </span>
  </el-dialog>
</template>
    
    <script>
export default {
  name: 'addApplyDialog',
  props: {
    showDialog: false,
  },
  data () {
    // let checkApplyName = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入应用名称'));
    //   }
      // else {
      //   let reg = /^[a-zA-Z_0-9]+$/
      //   if (!reg.test(value)) {
      //     callback(new Error('应用名称不符合规范'));
      //   } else {
      //   }
      //   callback();
      // }
    // };
    return {
      form: { name: '',code:'', },
      rules: {
        name: [{ required: true, message:'请输入应用名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入code编码', trigger: 'blur' }],
      },
    }
  },
  mounted () { },
  methods: {
    initData () { 
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.form.name = ''
        this.form.code =''
      })
    },
    closeFn () {
      this.$emit('closeDialog')
    },
    sureFn () {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$emit('sureDialog', this.form)
          }
        })
    }
  }
}
</script>
    
<style lang="scss"  scoped>
.inputWidth {
  width: 350px;
  margin-right: 15px;
}
</style>
</style>